import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user.vue')
  },
  {
    path: '/user_s',
    name: 'User_s',
    component: () => import('../views/user_s.vue')
  },
  {
    path: '/book',
    name: 'book',
    component: () => import('../views/book.vue')
  },
  {
    path: '/book2',
    name: 'book2',
    component: () => import('../views/book2.vue')
  },

  {
    path: '/yanz',
    name: 'yanz',
    component: () => import('../views/yanz.vue')
  },

  /*  */
  {
    path: '/lay',
    name: 'lay',
    component: () => import('../layout/index.vue'),
    children: [
      {
        path: '/lay/yu',
        name: 'yu',
        component: () => import('../views/lay/yu/yu.vue'),
        children: [
          {
            path: '/lay/yu/1',
            name: 'yu1',
            component: () => import('../views/lay/yu/yu_1.vue'),
            meta: {
              keepAlive: true
            }
          },
          {
            path: '/lay/yu/2',
            name: 'yu2',
            component: () => import('../views/lay/yu/yu_2.vue'),
          },
          {
            path: '/lay/yu/3',
            name: 'yu3',
            component: () => import('../views/lay/yu/yu_3.vue'),
          }
        ]
      },
      {
        path: '/lay/shu',
        name: 'shu',
        component: () => import('../views/lay/shu/index.vue'),
        children: [
          {
            path: '/lay/shu/1',
            name: 'shu1',
            component: () => import('../views/lay/shu/1.vue'),
          },
          {
            path: '/lay/shu/2',
            name: 'shu2',
            component: () => import('../views/lay/shu/2.vue'),
          },
          {
            path: '/lay/shu/3',
            name: 'shu3',
            component: () => import('../views/lay/shu/3.vue'),
          },
        ]
      },
      {
        path: '/lay/yin',
        name: 'yin',
        component: () => import('../views/lay/yin/index.vue'),
        children: [
          {
            path: '/lay/yin/1',
            name: 'yin1',
            component: () => import('../views/lay/yin/1.vue'),
          },
          {
            path: '/lay/yin/2',
            name: 'yin2',
            component: () => import('../views/lay/yin/2.vue'),
          },
        ]
      },
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
